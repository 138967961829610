import './App.scss';

import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, Redirect, matchPath } from 'react-router';

import { setFavicon } from './utils/utils';
import Nav from './components/Nav/Nav';
import Dashboards from './pages/Dashboards/Dashboards';
import DatabaseLogin from './pages/DatabaseLogin/DatabaseLogin';
import DatabaseServer from './pages/DatabaseServer/DatabaseServer';
import GxwebVersion from './pages/GxwebVersion/GxwebVersion';
import Home from './pages/Home/Home';
import HostingLocation from './pages/HostingLocation/HostingLocation';
import HostingServer from './pages/HostingServer/HostingServer';
import Instance from './pages/Instance/Instance';
import InstanceList from './pages/InstanceList/InstanceList';
import Login from './pages/Login/Login';
import Logs from './pages/Logs/Logs';
import NotFound from './pages/NotFound/NotFound';
import AcceptQuote from './pages/Quotation/AcceptQuotation';
import Quotation from './pages/Quotation/Quotation';
import QuoteDownloadLink from './pages/Quotation/QuoteDownloadLink';
import QuotationList from './pages/QuotationList/QuotationList';
import UserContext, { User, fetchUser } from './User.context';
import ConfigContext, { OdooConfig, fetchOdooConfig } from './Config.context';
import OTPFormValidate from './pages/BusinessDetails/OTPFormValidate';
import Contracts from './pages/Contracts/Contracts';
import EmployeeContracts from './pages/EmployeeContracts/EmployeeContracts';
import OTPFormValidateEmployee from './pages/EmployeeContracts/OTPForm';
import ExistingClientQuote from './pages/BusinessDetails/ExistingClientQuote';


const AUTH_LESS_PATHS = [
  '/quotation/acceptquote/:id/:jwt_token',
  '/quotation/acceptquote/:id/:jwt_token/:existing_client',
  '/contracts/:id/:debtorID',
  '/employee/contracts/otp_form/:jwt_token',
  '/employee/contracts/:jwt_token',
  '/quotation/otp_validate/:id',
] as const;

const isAuthless = (location: Location): boolean => {
  return AUTH_LESS_PATHS.some(pattern =>
    matchPath(location.pathname, {
      path: pattern,
      exact: true,
    })
  );
};

const App: React.FC = () => {
  const [odooConfig, setOdooConfig] = useState(undefined as OdooConfig);
  const [user, setUser] = useState(undefined as User);
  const location = useLocation();

  useEffect(() => {
    const initializeOdooConfig = async () => {
      const config = await fetchOdooConfig(setOdooConfig);
      if (config?.country_favicon) {
        setFavicon(config.country_favicon);
      }
    };

    initializeOdooConfig();
  }, []);

  useEffect(() => {
    if (!isAuthless(location)) {
      fetchUser(setUser);
    }
  }, [location]);

  return (
    <div className="App">
      <main>
        <ConfigContext.Provider value={odooConfig}>
          <UserContext.Provider value={user}>
            <Switch>
              <Route exact path="/">
                <Redirect exact from="/" to="/quotation" />
                <Nav />
                <Home />
              </Route>
              <Route path="/dashboards">
                <Nav />
                <Dashboards />
              </Route>
              <Route exact path="/instance">
                <Nav />
                <InstanceList />
              </Route>
              <Route path="/instance/:id">
                <Nav />
                <Instance />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/logs/:containerNames">
                <Nav />
                <Logs />
              </Route>
              <Route path="/hosting_location/:id">
                <Nav />
                <HostingLocation />
              </Route>
              <Route path="/database_login/:id">
                <Nav />
                <DatabaseLogin />
              </Route>
              <Route path="/database_server/:id">
                <Nav />
                <DatabaseServer />
              </Route>
              <Route path="/gxweb_version/:id">
                <Nav />
                <GxwebVersion />
              </Route>
              <Route path="/hosting_server/:id">
                <Nav />
                <HostingServer />
              </Route>
              <Route exact path="/quotation">
                <Nav />
                <QuotationList />
              </Route>
              <Route path="/quotation/acceptquote/:id/:jwt_token">
                <AcceptQuote />
              </Route>
              <Route path="/quotation/acceptquote/:id/:jwt_token/:existing_client">
                <ExistingClientQuote />
              </Route>
              <Route path="/quotation/download/:id">
                <Nav />
                <QuoteDownloadLink />
              </Route>
              <Route path="/quotation/otp_validate/:id">
                <OTPFormValidate />
              </Route>
              <Route path="/quotation/:id">
                <Nav />
                <Quotation />
              </Route>
              <Route path="/contracts/:id/:debtorID">
                <Nav />
                <Contracts />
              </Route>
              <Route path="/employee/contracts/otp_form/:jwt_token">
                <Nav />
                <OTPFormValidateEmployee />
              </Route>
              <Route path="/employee/contracts/:jwt_token">
                <Nav />
                <EmployeeContracts />
              </Route>
              <Route>
                <Nav />
                <NotFound />
              </Route>
            </Switch>
          </UserContext.Provider>
        </ConfigContext.Provider>
      </main>
    </div>
  );
};

export default App;
